/* global localStorage, fetch, FormData */

'use strict'

var filesSelector = '.js-files'
var formSelector = '.js-form'

var fmf = {
  init: function () {
    var filesEl = document.querySelector(filesSelector)

    if (filesEl) {
      fmf.files(filesEl)
    }

    var formEl = document.querySelector(formSelector)

    if (formEl) {
      fmf.form(formEl)
    }
  },

  files: function (el) {
    var formSelector = '.js-form'
    var filterSelector = '.js-formFilter'
    var itemSelector = '.js-fileItem'
    var titleSelector = '.js-fileTitle'

    var filesformEl = el.querySelector(formSelector)

    if (!filesformEl) {
      return
    }

    var filterEl = filesformEl.querySelector(filterSelector)
    var itemEls = el.querySelectorAll(itemSelector)

    /**
     * onFilesFilterChange
     * @param  {Object} event
     */
    function onFilesFilterChange (event) {
      filterFiles(event.currentTarget.value)
    }

    filterEl.addEventListener('change', onFilesFilterChange, false)

    /**
     * filterFiles
     * @param  {String} value
     */
    function filterFiles (value) {
      var files = Array.prototype.slice.call(itemEls)

      if (value === 'abc') {
        files.sort(sortByName)
      } else if (value === 'cba') {
        files.sort(sortByNameReverse)
      } else if (value === 'new') {
        files.sort(sortByDateNew)
      } else if (value === 'old') {
        files.sort(sortByDateOld)
      } else {
        console.log('?????')
      }

      files.forEach(function (item) {
        item.parentNode.appendChild(item)
      })

      saveFilter(value)
    }

    function sortByName (a, b) {
      var aTitle = a.querySelector(titleSelector).innerHTML.toUpperCase()
      var bTitle = b.querySelector(titleSelector).innerHTML.toUpperCase()

      if (aTitle < bTitle) {
        return -1
      }

      if (aTitle > bTitle) {
        return 1
      }

      return 0
    }

    function sortByNameReverse (a, b) {
      var aTitle = a.querySelector(titleSelector).innerHTML.toUpperCase()
      var bTitle = b.querySelector(titleSelector).innerHTML.toUpperCase()

      if (aTitle < bTitle) {
        return 1
      }

      if (aTitle > bTitle) {
        return -1
      }

      return 0
    }

    function sortByDateNew (a, b) {
      var aDate = new Date(a.getAttribute('data-date'))
      var bDate = new Date(b.getAttribute('data-date'))

      if (aDate < bDate) {
        return 1
      }

      if (aDate > bDate) {
        return -1
      }

      return 0
    }

    function sortByDateOld (a, b) {
      var aDate = new Date(a.getAttribute('data-date'))
      var bDate = new Date(b.getAttribute('data-date'))

      if (aDate < bDate) {
        return -1
      }

      if (aDate > bDate) {
        return 1
      }

      return 0
    }

    function saveFilter (value) {
      if (window.localStorage) {
        localStorage.setItem('file_filter', value)
      }
    }

    function getSavedFilter () {
      return window.localStorage ? localStorage.getItem('file_filter') : filterEl.value
    }

    filterFiles(getSavedFilter())
  },

  form: function (el) {
    var submitButtonSelector = '.js-submitButton'
    var responseSelector = '.js-formResponse'
    var activeClass = 'is-active'

    var submitButton = el.querySelector(submitButtonSelector)
    var responseEl = el.querySelector(responseSelector)

    var ajaxUrl = el.getAttribute('data-ajax')

    el.addEventListener('submit', onSubmit, false)

    function onSubmit (event) {
      responseEl.classList.remove(activeClass)
      responseEl.innerHTML = ''

      submitButton.disabled = true

      fetch(ajaxUrl, {
        method: 'post',
        body: new FormData(el)
      })
        .then(checkStatus)
        .then(parseJSON)
        .then(onComplete)
        .catch(onError)

      event.preventDefault()
      return false
    }

    function onComplete (data) {
      console.log(data)

      submitButton.disabled = false

      responseEl.innerHTML = data.message || data
      responseEl.classList.add(activeClass)

      scrollToResponse()
    }

    function onError (error) {
      console.log('Request failed', error)

      submitButton.disabled = false
    }

    function checkStatus (response) {
      if (response.status >= 200 && response.status < 300) {
        return response
      } else {
        var error = new Error(response.statusText)
        error.response = response
        throw error
      }
    }

    function parseJSON (response) {
      return response.json()
    }

    function scrollToResponse () {
      if (!responseEl) {
        return
      }

      window.scroll({
        'left': 0,
        'top': window.pageYOffset + responseEl.getBoundingClientRect().top - 80,
        'behavior': 'smooth'
      })
    }
  }
}

if (document.readyState !== 'loading') {
  fmf.init()
} else {
  document.addEventListener('DOMContentLoaded', fmf.init, false)
}
